<template>
    <AuthLayout>
        <div class="alert alert-info text-center h4" role="alert">
            You're now on the <b>Notifications Page</b>, that's still under construction. Sorry for the inconvenience.
        </div>
    </AuthLayout>
</template>

<script>

    export default {
        name: 'Notifications',

        computed: {
            
        },
    }
</script>